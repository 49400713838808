import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <img
          className="img-fluid"
          src={require("../assets/images/logo-v2.svg")}
          alt="Omeda-Med"
        />
        <div className="row">
          <div className="col-xl-2 col-md-3">
            <h4>Adres</h4>
            <address>
              Ul. Konfederacka 11 <br />
              30-306 Kraków
            </address>
            <h5>Godziny otwarcia</h5>
            <p className="no-p">
              <span>Pn-Pt</span> 11:00–21:00
            </p>
            <p>
              <span>Sob-Nd</span> Nieczynne
            </p>
          </div>
          <div className="col-xl-3 col-md-4">
            <h4>Kontakt</h4>
            <p>Rejestracja od 09:00 - 20:00</p>
            <a
              href="tel:+48600314600"
              className="footer__link footer__link--phone"
            >
              +48 600 314 600
            </a>
            <br />
            <a
              href="mailto:biuro@omedamed.pl"
              className="footer__link footer__link--email"
            >
              biuro@omedamed.pl
            </a>
          </div>
          <div className="col-xl-2 offset-xl-2 col-md-3">
            <div className="footer__nav">
              <Link to="/">Strona Główna</Link>
              <Link to="/nasi-specjalisci/">Nasi specjaliści</Link>
              <Link to="/dlaczego-my/">Dlaczego my</Link>
              <Link to="/o-nas/">O nas</Link>
              <Link to="/medika-1/">Medika 1</Link>
              <Link to="/oferta/">Oferta</Link>
            </div>
          </div>
          <div className="col-xl-2 col-md-2">
            <div className="footer__nav">
              <Link to="/cennik/">Cennik</Link>
              <Link to="/blog/">Blog</Link>
              <Link to="/kariera/">Kariera</Link>
              <Link to="/kontakt/">Kontakt</Link>
              <Link to="/rejestracja/" className="turqoise">
                Rejestracja
              </Link>
            </div>
          </div>
        </div>
        <div className="footer__copy">
          <p>&copy; Omeda-Med</p>
          <a href="https://adream.pl/" target="_blank" rel="noreferrer">
            Projekt i wykonanie
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
