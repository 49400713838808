import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

const ModalRegister = ({ isOpen, onClose }) => {
  const query = useStaticQuery(graphql`
    {
      allWpSpecialist {
        nodes {
          id
          title
          content
          acfSpecialists {
            specjalizacja
            specjalistaProassistIframeUrl
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  `)

  const [value, setValue] = useState("")

  const data = query.allWpSpecialist.nodes
    ?.map(item => ({
      title: item.title,
      role: item.acfSpecialists.specjalizacja,
      url: item.acfSpecialists.specjalistaProassistIframeUrl,
    }))
    ?.filter(item => item.url)

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }

    return () => (document.body.style.overflow = "")
  }, [isOpen])

  return (
    isOpen && (
      <div className="modal-register">
        <div className="modal-register__inner">
          <button
            onClick={() => {
              setValue("")

              if (onClose && typeof onClose === "function") {
                onClose()
              }
            }}
            className="modal-register__close"
          >
            Zamknij
          </button>
          <div>
            <h2>Wybierz specjalistę i umów wizytę</h2>
            {data?.length > 0 && (
              <div className="modal-register__select">
                <select
                  value={value ? `${value.title}|${value.url}` : ""}
                  onChange={e => {
                    const value = e.target.value
                    const data = value.split("|")

                    setValue({
                      title: data[0],
                      url: data[1],
                    })
                  }}
                >
                  <option disabled value="">
                    Wybierz specjalistę
                  </option>
                  {data.map(item => (
                    <option
                      key={item.title}
                      value={`${item.title}|${item.url}`}
                    >
                      {item.title} - {item.role}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {value && value !== "" && (
              <iframe
                src={value.url}
                width="100%"
                height="496"
                frameBorder="0"
                title={value.title}
              />
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default ModalRegister
