import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import $ from "jquery"

import ModalRegister from "./modalRegister"

const Header = ({ light }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isOfferOpen, setIsOfferOpen] = useState(false)
  const [isRegisterOpen, setIsRegisterOpen] = useState(null)

  useEffect(() => {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop()
      if (scroll >= 1) {
        $("header").addClass("shrink")
      } else {
        $("header").removeClass("shrink")
      }
    })
  })

  if (typeof window !== "undefined") {
    if (isMenuOpen === true) {
      document.body.classList.add("appleFix")
    } else {
      document.body.classList.remove("appleFix")
    }
  }

  return (
    <>
      <header className={`header${light ? " light" : ""}`}>
        <div className="header__wrapper">
          <Link to="/" className="header__brand">
            <img
              className="img-fluid"
              src={require("../assets/images/logo-v2.svg")}
              alt="Gabinety Omeda-Med"
            />
            <img
              className="img-fluid logo-dark"
              src={require("../assets/images/logo-dark-v2.svg")}
              alt="Gabinety Omeda-Med"
            />
          </Link>
          <nav className={`header__nav${isMenuOpen === true ? " open" : ""}`}>
            <Link to="/nasi-specjalisci/" activeClassName="current">
              Nasi specjaliści
            </Link>
            <Link to="/dlaczego-my/" activeClassName="current">
              Dlaczego my
            </Link>
            <Link to="/o-nas/" activeClassName="current">
              O nas
            </Link>
            <Link to="/medika-1/" activeClassName="current">
              Medika 1
            </Link>
            <div
              className={`header__dropdown${
                isOfferOpen ? " header__dropdown--open" : ""
              }`}
            >
              <Link to="/oferta/" activeClassName="current">
                Oferta
              </Link>
              <button
                className="header__dropdown-toggle"
                onClick={() => setIsOfferOpen(prevOpen => !prevOpen)}
              >
                Rozwiń
              </button>
              <div className="header__dropdown-menu">
                <div className="header__dropdown-menu-inner">
                  <Link to="/oferta/podologia/" activeClassName="current">
                    Podologia
                  </Link>
                  <Link to="/oferta/fizjoterapia/" activeClassName="current">
                    Fizjoterapia
                  </Link>
                  <Link to="/oferta/chirurgia-palca/" activeClassName="current">
                    Chirurgia palca
                  </Link>
                  <Link to="/oferta/kriochirurgia/" activeClassName="current">
                    Kriochirurgia
                  </Link>
                  <Link to="/oferta/laseroterapia/" activeClassName="current">
                    Laseroterapia
                  </Link>
                  {/* <Link to="/oferta/psychologia/" activeClassName="current">
                    Psychologia
                  </Link> */}
                  <Link to="/oferta/dietetyka/" activeClassName="current">
                    Dietetyka
                  </Link>
                  <Link to="/oferta/pielegniarstwo/" activeClassName="current">
                    Pielęgniarstwo
                  </Link>
                </div>
              </div>
            </div>
            <Link to="/cennik/" activeClassName="current">
              Cennik
            </Link>
            <Link to="/opinie-klientow/" activeClassName="current">
              Opinie
            </Link>
            <Link to="/blog/" activeClassName="current">
              Blog
            </Link>
            <Link to="/kariera/" activeClassName="current">
              Kariera
            </Link>
            <Link to="/kontakt/" activeClassName="current">
              Kontakt
            </Link>
            <button
              className="turqoise"
              onClick={() => setIsRegisterOpen(true)}
            >
              Rejestracja
            </button>
          </nav>
        </div>
        <button
          type="button"
          aria-label="Open Menu"
          className={`hamburger hamburger--squeeze ${
            isMenuOpen ? "is-active" : ""
          }`}
          onClick={e => setIsMenuOpen(!isMenuOpen)}
        >
          {Array(4)
            .fill(null)
            .map((item, index) => (
              <span key={index} />
            ))}
        </button>
      </header>

      <a className="header__contact" href="tel:+48600314600">
        Telefon
        <img src={require("../assets/images/phone_icon.svg")} alt="" />
      </a>

      <ModalRegister
        isOpen={isRegisterOpen}
        onClose={() => setIsRegisterOpen(false)}
      />
    </>
  )
}

export default Header
